.top
  justify-content: space-between
  margin-bottom: 16px

.message
  word-break: break-all
  white-space: pre-wrap
  max-width: 100%
  font-weight: 400
  font-size: 16px
  line-height: 25px

.showDecoded
  margin-left: 16px
