.center
  position: relative
  display: flex
  align-items: center
  justify-content: center

.votesContainer
  margin-top: 16px
  
  .abstainBean
    display: block
    content: ''
    border-radius: 3px
    width: 12px
    height: 6px

.root
  display: flex
  flex-direction: column
  gap: 8px
  position: relative
  margin: 36px 0
  min-width: 320px
  flex: 1

  .bar
    width: 100%
    border-radius: 4px
    height: 8px

    .quorum
      position: absolute
      top: -16px
      height: 12px
      width: 1px

      .value
        white-space: nowrap
        position: absolute
        top: -20px

    .total
      height: 100%
      border-radius: 4px
      display: flex

      .abstain
        height: 100%
        flex: 1
        border-top-right-radius: 4px
        border-bottom-right-radius: 4px
        
        .value
          white-space: nowrap
          position: absolute
          bottom: -40px

      .no
        height: 100%
        flex: 1

        .value
          white-space: nowrap
          position: absolute
          bottom: -40px

      .yes
        height: 100%
        border-top-left-radius: 4px
        border-bottom-left-radius: 4px

        .value
          white-space: nowrap
          position: absolute
          bottom: -40px
