.root
  height: 80px

  display: flex

  padding: 56px 32px 56px 32px

  width: 100%
  align-items: center
  justify-content: space-between

  .navigation
    display: flex
    flex-direction: row
    align-items: center
    gap: 48px