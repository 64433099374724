.root
  display: grid
  gap: 16px
  grid-template-columns: 228px 1fr

.name
  font-weight: 500

.change
  display: flex
  gap: 12px
  align-items: center

  .oldValue
    font-weight: 500
    text-decoration-line: line-through

  .newValue
    font-weight: 500
