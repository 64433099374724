.root
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  align-items: flex-start

  .container
    display: grid
    grid-row-gap: 32px !important
    background-image: url("./assets/landing-shape.png")
    background-repeat: no-repeat
    background-position: right top
    background-size: 30% 
    