.root
  display: flex
  flex-direction: column
  gap: 24px

  .section
    display: flex
    gap: 16px

    .button
      margin-top: 40px
