.root
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  &.mobile
    gap: 68px
    margin-top: 32px
    padding: 0 24px 64px 24px
  &:not(.mobile)
    gap: 68px
    margin-top: 96px
    padding: 0 24px 120px 24px

  h1
    max-width: 1060px
    text-align: center

