.root
  display: flex
  flex-direction: column
  gap: 48px
  align-items: center
  text-align: center
  max-width: 1040px
  .description
    max-width: 840px
    font-weight: 400
